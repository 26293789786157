import React from 'react';
import SocialsRow from './SocialsRow';

const Footer: React.FC = () => {
  return ( 
    <footer className="bg-color text-color p-3 mt-auto">
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-4 col-12">
            <img src="https://placehold.co/500x200" alt='placeholder' className='w-100 pe-lg-5 pe-md-3 pe-0'/>
          </div>
          <div className="col-md-4 col-12 text-center my-4">
            <h4>FOLLOW US ON</h4>
            <SocialsRow />
          </div>
          <div className="col-md-4 col-12">
            <img src="https://placehold.co/500x200" alt='placeholder' className='w-100 ps-lg-5 ps-md-3 ps-0'/>
          </div>
        </div>
        <div className="row text-center mt-3">
          <div>Copyright © {new Date().getFullYear()} AIGF</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;