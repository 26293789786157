import React from 'react';

const SocialsRow: React.FC = () => {
  return (
    <div className="row my-3">
      <div className="col-3">
        <img src="/img/instagram512.png" alt="Instagram" width={50} />
      </div>
      <div className="col-3">
        <img src="/img/x512.png" alt="X" width={50} />
      </div>
      <div className="col-3">
        <img src="/img/discord512.png" alt="Discord" width={50} />
      </div>
      <div className="col-3">
        <img src="/img/reddit512.png" alt="Reddit" width={50} />
      </div>
    </div>
  );
};

export default SocialsRow;