import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import AppRoutes from './AppRoutes';

const App: React.FC = () => {
  return (
    <Router>
      <Navbar />
      <div className="d-flex flex-column min-vh-100">
        <main className="container flex-fill">
          <AppRoutes />
        </main>
        <Footer />
      </div>
    </Router>
  );
};


export default App;
